<template>
    <div class="dark:bg-gray-900 bg-white py-24 text-gray-100">
        <div class="lg:container mx-auto">
            <a class="py-3 px-6 bg-blue-400 sm:m-10 lg:m-0 hover:bg-teal-400 cursor-pointer"
               @click.prevent="$router.back()">Назад</a>
            <h1 class="sm:p-10 lg:p-0 text-4xl dark:text-white text-gray-700 my-10">{{ item.title }}</h1>
            <div class="dark:bg-gray-800 rounded p-10 dark:shadow-none shadow-2xl">
                <div class="px-6 flex justify-between mb-5">
          <span class=" text-md text-gray-700 dark:text-gray-300 pb-10">
          {{ item.salary }}
        </span>
                    <span class="block text-right text-md text-gray-700 dark:text-gray-300 pb-10">{{
                            item.created_at
                        }}</span>
                </div>


                <div class="text-lg flex flex-col items-center dark:text-white text-gray-700 custom-style"
                     v-html="item.detail"></div>
            </div>
        </div>

    </div>
</template>

<script>

import axios from "axios";

export default {
    meta: {
        title: 'Вакансии'
    },
    name: "CareerDetail",
    data: () => ({
        item: {}
    }),
    created() {
        this.fetch()
    },
    methods: {
        async fetch() {

            let code = this.$route.params.code
            axios.get(`https://declarant.by/rest/career/${code}`)
                .then(res => {
                    this.item = res.data.data
                })

        }

    }
}
</script>

<style scoped>

.custom-style >>> * {
    line-height: inherit !important;
    font-family: "Montserrat", sans-serif !important;
    font-style: inherit !important;
    font-weight: inherit !important;
    font-size: inherit !important;
    color: inherit !important;
}


</style>